import React from 'react';

export const SuccessIcon: React.FC<{ className?: string }> = (props) => {
  return (
    <svg
      className={props.className}
      width="98"
      height="98"
      viewBox="0 0 98 98"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4479_15004)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M49 0C21.9734 0 0 21.9734 0 49C0 76.0266 21.9734 98 49 98C76.0266 98 98 76.0266 98 49C98 21.9734 76.0266 0 49 0Z"
          fill="currentColor"
        />
        <mask
          id="mask0_4479_15004"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="2"
          y="2"
          width="93"
          height="93"
        >
          <circle cx="48.5" cy="48.5" r="46.5" fill="white" />
        </mask>
        <g mask="url(#mask0_4479_15004)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M72.6963 32.4825C73.883 33.6692 73.883 35.6216 72.6963 36.8083L43.9854 65.5192C43.392 66.1126 42.6072 66.4188 41.8225 66.4188C41.0377 66.4188 40.2529 66.1126 39.6596 65.5192L25.3041 51.1638C24.1174 49.977 24.1174 48.0247 25.3041 46.838C26.4908 45.6513 28.4432 45.6513 29.6299 46.838L41.8225 59.0306L68.3705 32.4825C69.5572 31.2767 71.5096 31.2767 72.6963 32.4825Z"
            fill="white"
          />
        </g>
        <circle cx="27.5" cy="48.5" r="2.5" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_4479_15004">
          <rect width="98" height="98" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
