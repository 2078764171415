import styled, { css } from 'styled-components';
import { Button } from 'antd';

import Loader from '~/ui/components/Loader/Loader';
import { baseFont, fontSize, FontSizeProps } from '~/ui/kit/constants/fonts';
import { BorderRadiusProps, gaps, getBorderRadius } from '~/ui/kit/constants/sizes';

import { ButtonProps } from './Button.atom.types';

const primaryStyles = css<{ ghost?: ButtonProps['ghost'] }>`
  --eko-button-outline-color: var(--eko-primary-color-light);

  &&-primary:not(:disabled):not(.ant-btn-background-ghost) {
    &,
    &:hover,
    :active {
      background: var(--eko-primary-color);
    }
  }

  &.ant-btn-background-ghost {
    &,
    &:not(:disabled):hover {
      background: transparent;
      color: var(--eko-primary-color);
      border: 2px solid currentColor;
    }
  }
`;

const linkStyles = css<{ ghost?: ButtonProps['ghost'] }>`
  --eko-button-outline-color: var(--eko-primary-color-light);
  color: var(--eko-primary-color);
  border: none;
  padding: 0;
  background: none;

  &,
  &:hover,
  :active {
    color: var(--eko-primary-color);
  }
`;

const defaultStyles = css`
  color: var(--eko-third-color);
  background: none;
  border: 2px solid currentColor;
  --wave-color: currentColor;
  --eko-button-outline-color: var(--eko-third-color-dark-2);

  &,
  &:hover,
  &:not(:disabled):active {
    color: var(--eko-third-color);
    border-color: currentColor;
  }

  &:focus-visible {
    outline-color: currentColor;
  }
`;

const getStylesByType = css<{ type?: ButtonProps['type']; ghost?: ButtonProps['ghost'] }>`
  ${({ type }) => {
    switch (type) {
      case 'primary':
        return primaryStyles;
      case 'ghost': {
        return '';
      }
      case 'link': {
        return linkStyles;
      }
    }

    return defaultStyles;
  }}
`;

const getStylesBySize = css<{ size?: ButtonProps['size'] }>`
  ${({ size }) => {
    switch (size) {
      case 'middle': {
        return css`
          padding: ${gaps.s}px ${gaps.m}px;
        `;
      }
      case 'large': {
        return css`
          padding: ${gaps.m}px ${gaps.l}px;
        `;
      }
    }
  }}
`;

const getLinkStyles = css<{ $href?: string; $noDecoration?: boolean; type?: ButtonProps['type'] }>`
  ${({ type, $href, $noDecoration }) => {
    if (!$noDecoration && type === 'link' && !!$href) {
      return css`
        span {
          text-decoration: underline;
        }
      `;
    }
  }}
`;

export const Container = styled(Button)<
  { $href?: string; $noDecoration?: boolean } & BorderRadiusProps & FontSizeProps
>`
  &.ant-btn {
    --wave-color: transparent;
    --eko-button-outline-color: transparent;
    box-shadow: none;
    height: auto;
    border: none;
    ${baseFont};
    ${fontSize};
    ${getStylesBySize};
    ${getStylesByType};
    ${getBorderRadius};
    ${getLinkStyles}

    & span {
      font-family: inherit;
    }

    & a {
      text-decoration: none;
      color: inherit;
    }

    & .spinner-border {
      --bs-spinner-width: 1em;
      --bs-spinner-height: 1em;
    }

    &:not(:disabled):focus-visible {
      outline-color: var(--eko-button-outline-color);
    }
  }
`;

export const Spinner = styled(Loader)`
  &:after {
    content: ' ';
  }
`;
