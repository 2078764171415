import styled, { css } from 'styled-components';
import { baseFont, fontSize } from '~/ui/kit/constants/fonts';
import { gaps, getEkoBorderRadius } from '~/ui/kit/constants/sizes';
import { media } from '~/ui/utils/media';

export const Container = styled.div.attrs({ $br: 'l' })`
  background-color: var(--eko-secondary-color);
  overflow: hidden;
  border-radius: ${getEkoBorderRadius({ $br: 'l' })};

  ${media.md`
    border-radius: ${getEkoBorderRadius({ $br: 'xl' })};
  `}
`;

export const cardHeaderCommonStyles = css`
  ${baseFont};
  font-weight: 600;
  color: var(--eko-primary-color);
  background: var(--eko-primary-color-light-2);
  padding: ${gaps.xxs}px ${gaps.m}px;

  ${media.lg`
    padding: ${gaps.xxs}px ${gaps.xl}px;
  `}
`;

export const cardContentStyles = css<{ $disableContentPadding?: boolean }>`
  ${({ $disableContentPadding }) =>
    !$disableContentPadding &&
    css`
      padding: ${gaps.m}px ${gaps.m}px;

      ${media.md`
      padding: ${gaps.m}px ${gaps.xl}px;
    `}
    `}
`;

export const Content = styled.div<{ $disableContentPadding?: boolean }>`
  ${cardContentStyles};
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  flex-direction: column;
  gap: ${gaps.xxs / 2}px;

  ${media.md`
    gap: ${gaps.xxs}px;
  `};
`;

export const BeforeContainer = styled.div.attrs({ $fs: 's' })`
  color: var(--eko-title-color);
  font-weight: bold;
  ${fontSize};
`;

export const ChildrenContent = styled.div``;
