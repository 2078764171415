import { useLocation } from 'react-router-dom';

import * as ROUTES from '@routes/names';
import { PageOnboardingProps } from '~/widgets/PageOnboarding';

import { useGetPortfolioPageOnboarding } from '../portfolio';
import { PAGE_EMPTY_ONBOARDING_RESULTS } from '../page.onboarding.constants';

export const usePageOnboarding = (enabled = true): PageOnboardingProps => {
  const location = useLocation();
  const portfolioPageOnboarding = useGetPortfolioPageOnboarding(location.pathname === ROUTES.PORTFOLIO);

  if (!enabled) {
    return PAGE_EMPTY_ONBOARDING_RESULTS;
  }

  if (location.pathname === ROUTES.PORTFOLIO && portfolioPageOnboarding.steps.length > 0) {
    return {
      id: 'portfolio_page_tour',
      isOpen: portfolioPageOnboarding.isActive,
      isLoading: portfolioPageOnboarding.isLoading,
      steps: portfolioPageOnboarding.steps,
      welcomePopupInfo: portfolioPageOnboarding.welcomePopupInfo,
      onComplete: portfolioPageOnboarding.onComplete,
    };
  }

  return PAGE_EMPTY_ONBOARDING_RESULTS;
};
