import React from 'react';

type Props = {
  className?: string;
};

export const IconCrossRounded: React.FC<Props> = (props) => {
  return (
    <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2003_21457)">
        <path
          // eslint-disable-next-line max-len
          d="M12 24C5.38312 24 0 18.6169 0 12C0 5.38312 5.38312 0 12 0C18.6169 0 24 5.38312 24 12C24 18.6169 18.6169 24 12 24ZM12 1.71423C6.32849 1.71423 1.71423 6.32849 1.71423 12C1.71423 17.6715 6.32849 22.2858 12 22.2858C17.6715 22.2858 22.2858 17.6715 22.2858 12C22.2858 6.32849 17.6715 1.71423 12 1.71423Z"
          fill="currentColor"
        />
        <path
          // eslint-disable-next-line max-len
          d="M16.2855 17.1411C16.0662 17.1411 15.8468 17.0574 15.6795 16.8899L7.10811 8.31855C6.77321 7.98365 6.77321 7.44129 7.10811 7.10658C7.44283 6.77186 7.98537 6.77168 8.32008 7.10658L16.8914 15.6779C17.2263 16.0128 17.2263 16.5552 16.8914 16.8899C16.7241 17.0574 16.5049 17.1411 16.2855 17.1411Z"
          fill="currentColor"
        />
        <path
          // eslint-disable-next-line max-len
          d="M7.714 17.1412C7.49464 17.1412 7.27547 17.0575 7.10811 16.89C6.77321 16.5553 6.77321 16.0127 7.10811 15.678L15.6795 7.10664C16.0144 6.77174 16.5567 6.77174 16.8914 7.10664C17.2262 7.44136 17.2263 7.9839 16.8914 8.31862L8.32008 16.89C8.15273 17.0575 7.93336 17.1412 7.714 17.1412Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_2003_21457">
          <rect width="24" height="24" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  );
};
