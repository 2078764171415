import React, { useMemo } from 'react';
import * as S from './Skeleton.styled';

type Props = {
  className?: string;
  lines: number;
};
export const LinesSkeleton: React.FC<Props> = (props) => {
  return useMemo(
    () => (
      <div className={props.className}>
        {new Array(props.lines).fill(null).map((_, index) => (
          <S.LinesItemSkeleton key={index} />
        ))}
      </div>
    ),
    [props.lines, props.className],
  );
};
