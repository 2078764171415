import React from 'react';

import { MenuSkeleton } from '~/ui/kit/organisms/Menu/Menu.skeleton';

import * as S from './NavMenu.skeleton.styled';

type Props = {
  className?: string;
  skeletonItemsCount?: number;
};

export const NavMenuSkeleton: React.FC<Props> = (props) => {
  return (
    <S.Container className={props.className}>
      <S.NavMenuContainer>
        <MenuSkeleton skeletonItemsCount={props.skeletonItemsCount || 8} />
      </S.NavMenuContainer>
      <S.ContentContainer>
        <S.Content />
      </S.ContentContainer>
    </S.Container>
  );
};
