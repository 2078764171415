import React, { FC } from 'react';

type Props = {
  className?: string;
};

export const IconWebCursor: FC<Props> = (props) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <g clipPath="url(#clip0_929_1144)">
        <path
          // eslint-disable-next-line max-len
          d="M10.105 18.0664C10.0617 18.0314 10.0259 18.0081 9.98002 17.9698C9.41502 17.3714 8.52002 16.3223 7.74002 14.9814H9.04919L8.47919 13.3148H6.91836C6.50752 12.2864 6.23086 11.1589 6.23086 9.98145C6.23086 8.80395 6.50919 7.67728 6.92169 6.64811H13.0334C13.2784 7.26145 13.4834 7.90478 13.6025 8.57895L15.355 9.17895C15.2792 8.29395 15.0809 7.44728 14.8109 6.64895H17.6134C18.0225 7.58145 18.2592 8.60311 18.2984 9.67561C18.3025 9.77811 18.305 10.1189 18.3034 10.1881L19.9417 10.7489C19.9609 10.4948 19.9809 10.2414 19.9809 9.98311C19.9817 4.46728 15.495 -0.0185547 9.98169 -0.0185547C4.46836 -0.0185547 -0.0183105 4.46728 -0.0183105 9.98145C-0.0183105 15.4956 4.46752 19.9814 9.98169 19.9814C10.24 19.9814 10.495 19.9681 10.7492 19.9489L10.105 18.0664ZM7.74002 4.98145C8.51752 3.64561 9.41752 2.59145 9.98169 1.99311C10.545 2.58978 11.44 3.64728 12.2159 4.98145H7.74002ZM16.6317 4.98145H14.1159C13.5134 3.77228 12.79 2.74061 12.1492 1.94395C13.9634 2.43395 15.53 3.51978 16.6317 4.98145ZM7.80419 1.94645C7.16419 2.74061 6.44502 3.77811 5.84336 4.98145H3.32919C4.42919 3.52228 5.99419 2.43728 7.80419 1.94645ZM2.34836 6.64811H5.14836C4.79669 7.68561 4.56502 8.80395 4.56502 9.98145C4.56502 11.1589 4.79669 12.2773 5.14836 13.3148H2.34836C1.90002 12.2931 1.64752 11.1664 1.64752 9.98145C1.64752 8.79645 1.90002 7.66978 2.34836 6.64811ZM3.33002 14.9814H5.84419C6.45086 16.1956 7.17669 17.2223 7.82002 18.0206C6.00252 17.5314 4.43336 16.4448 3.33002 14.9814ZM20.0184 12.5356L14.9284 14.9306L12.5334 20.0189L8.64169 8.64311L20.0184 12.5356Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_929_1144">
          <rect width="20" height="20" fill="transparent" />
        </clipPath>
      </defs>
    </svg>
  );
};
