import React from 'react';

import { getLastDigits } from '@utils/getLastDigits.helper';

import * as Styled from './AccountNumber.atom.styled';

interface Props {
  number: string;
  className?: string;
}

export const AccountNumber: React.FC<Props> = ({ number, className }) => {
  const digits = getLastDigits(number);

  return <Styled.Container className={className}>{`•••• ${digits}`}</Styled.Container>;
};
