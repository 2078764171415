import { InputNumber } from 'antd';
import styled from 'styled-components';

import { getBorderRadius } from '~/ui/kit/constants/sizes';
import { fontSize, font } from '~/ui/kit/constants/fonts';

import * as CommonStyles from './Input.styled';
import { InputStyledProps } from './Input.types';

export const Number = styled(InputNumber).attrs<InputStyledProps>({
  type: 'number',
})<InputStyledProps>`
  &.ant-input-number {
    width: 100%;
    ${({ $bordered }) => ($bordered ? CommonStyles.borderedStyles : CommonStyles.underlinedBorder)};
    ${CommonStyles.errorColors};
    ${getBorderRadius};
    ${fontSize};
    ${font};
  }
`;
