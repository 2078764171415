import styled from 'styled-components';

import { gaps } from '~/ui/kit/constants/sizes';
import { media } from '~/ui/utils/media';
import { Card } from '~/ui/kit/organisms/Card';
import { ChildrenContent, Content } from '~/ui/kit/organisms/Card/Card.organism.styled';
import { Menu } from '~/ui/kit/organisms/Menu';

export const Container = styled(Card)`
  @media (max-width: 991px) {
    border-radius: 0 !important;
  }

  ${Content} {
    padding: ${gaps.xl}px;
    @media (max-width: 991px) {
      padding: 0;
    }
  }

  ${ChildrenContent} {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    flex-wrap: wrap;
  }
`;

export const NavMenuContainer = styled.div`
  width: 100%;

  ${media.lg`
    max-width: 280px;
  `}
`;

export const NavMenu = styled(Menu)`
  color: var(--eko-primary-color);
  width: 100%;
`;

export const ContentContainer = styled.div`
  display: none;
  flex: 1;

  ${media.lg`
      display: flex;
      flex-direction: row;
  `}

  &:before {
    content: '';
    height: 100%;
    margin: 0 ${gaps.xxl}px;
    border: 0.5px solid var(--eko-fifth-color);
  }
`;
