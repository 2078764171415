import React, { useMemo } from 'react';

import * as S from './StringLink.styled';

type Props = {
  className?: string;
  stringUrl?: string;
  emptyView?: React.ReactNode;
};

export const StringLink: React.FC<Props> = (props) => {
  return useMemo(() => {
    try {
      const urlWithProtocol = (props.stringUrl || '').startsWith('http')
        ? props.stringUrl
        : `https://${props.stringUrl}`;

      const url = new URL(urlWithProtocol || '');
      const [www, origin] = url.host.split('www.');

      return (
        <S.Container>
          <S.SiteIcon />
          <S.SiteLink href={urlWithProtocol}>{origin || www}</S.SiteLink>
        </S.Container>
      );
    } catch (_) {
      return <>{props.emptyView}</> || null;
    }
  }, [props.stringUrl, props.emptyView]);
};
