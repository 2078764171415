import React from 'react';

import * as S from './Divider.styled';

type Props = {
  className?: string;
};

export const Divider: React.FC<Props> = (props) => {
  return <S.Container className={props.className}></S.Container>;
};
