import React, { CSSProperties, PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import * as RD from '@devexperts/remote-data-ts';
import classes from 'classnames';
import { pipe } from 'fp-ts/function';
import isAfter from 'date-fns/isAfter';
import addDays from 'date-fns/addDays';

import s from './SideBarLayout.module.css';

import { useOnResize } from '~/ui/utils/effects/useOnResize.hook';
import { sideBar as sbClasses } from '~/constants/customization';

import Notifications from '@components/layout/notification/Notifications';

import { selectNotifications } from '@store/application/selectors';

import { LegalDisclaimerPopup } from '~/widgets/LegalDisclaimerPopup';

import * as S from './Sidebar.styled';
import { organizationSelector } from '@store/organization/selectors';
import { profileSettingsSelector, useSettingsEq } from '@store/settings';
import { Header } from '~/widgets/Header';

import { usePageOnboarding, PORTFOLIO_SWITCHER_CN } from './onboardings';

export default function SideBarLayout({ children }: PropsWithChildren) {
  const organization = useSelector(organizationSelector);
  const userSettings = useSelector(profileSettingsSelector, useSettingsEq.equals);

  const headerRef = React.createRef<HTMLDivElement>();
  const calculateHeaderHeight = useCallback(() => {
    return headerRef.current?.offsetHeight || 0;
  }, [headerRef]);
  const [headerHeight, setHeaderHeight] = useState(calculateHeaderHeight());
  const onResize = useCallback(() => {
    setHeaderHeight(calculateHeaderHeight());
  }, [calculateHeaderHeight]);

  useOnResize(onResize);

  const notifications = useSelector(selectNotifications);

  const _showDisclaimer = useMemo(() => {
    const startedWithAlpacaOnboarding = window.sessionStorage.getItem('eko-starts-alpaca-onboarding');
    if (startedWithAlpacaOnboarding === 'true') {
      return false;
    }

    const showInitialDisclaimer = pipe(
      userSettings,
      RD.map((settings) => !settings.dontShowInitialDisclaimer),
      RD.fold(
        () => false,
        () => false,
        () => true,
        (value) => value,
      ),
    );

    const hideInitialDisclaimerDate = pipe(
      userSettings,
      RD.map((settings) => settings.hideInitialDisclaimerDate),
      RD.toUndefined,
    );

    if (
      !RD.isPending(userSettings) &&
      organization &&
      organization.organizationInfo &&
      organization.organizationInfo.show_initial_disclaimer !== undefined &&
      organization.organizationInfo.show_initial_disclaimer
    ) {
      if (organization.organizationInfo.disclaimer_expiration_days_number === 0) {
        return true;
      }

      if (hideInitialDisclaimerDate && organization.organizationInfo.disclaimer_expiration_days_number !== undefined) {
        return isAfter(
          new Date(),
          addDays(hideInitialDisclaimerDate, organization.organizationInfo.disclaimer_expiration_days_number),
        );
      }

      return showInitialDisclaimer;
    }

    return false;
  }, [organization, userSettings]);

  const [showDisclaimer, setShowInitialDisclaimer] = useState(false);

  useEffect(() => {
    setShowInitialDisclaimer(_showDisclaimer);
  }, [_showDisclaimer]);

  const onCloseDisclaimer = useCallback(() => {
    setShowInitialDisclaimer(false);
  }, []);

  const pageOnboarding = usePageOnboarding();

  const onboardingHasPortfolioSwitcher = useMemo(() => {
    return !!pageOnboarding.steps.find((step) => step.className === PORTFOLIO_SWITCHER_CN);
  }, [pageOnboarding.steps]);

  const headerStyles: CSSProperties = useMemo(() => {
    return onboardingHasPortfolioSwitcher && pageOnboarding.isOpen ? { position: 'absolute', zIndex: 'auto' } : {};
  }, [onboardingHasPortfolioSwitcher, pageOnboarding.isOpen]);

  return (
    <>
      <S.GlobalStyles $headerHeight={headerHeight} />
      {showDisclaimer && <LegalDisclaimerPopup onClose={onCloseDisclaimer} />}
      <S.Container className={classes(s.layout, s.layout_fullWidth)}>
        <S.Onboarding
          isLoading={pageOnboarding.isLoading}
          welcomePopupInfo={pageOnboarding.welcomePopupInfo}
          onComplete={pageOnboarding.onComplete}
          id={pageOnboarding.id}
          isOpen={!showDisclaimer && pageOnboarding.isOpen}
          steps={pageOnboarding.steps}
        >
          <Header style={headerStyles} selectorClassName={PORTFOLIO_SWITCHER_CN} ref={headerRef} />
        </S.Onboarding>

        <div className={classes('container')}>
          <div className={classes(s['layout__content'], sbClasses.content)}>
            {!!notifications.length && <Notifications notifications={notifications} fullScreen={false} />}
            {children}
          </div>
        </div>
      </S.Container>
    </>
  );
}
