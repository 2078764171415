import React from 'react';

export const IconInfo: React.FC<{ className?: string }> = (props) => (
  <svg
    className={props.className}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_5076_14424)">
      <path
        // eslint-disable-next-line max-len
        d="M10 0C4.47301 0 0 4.4725 0 10C0 15.5269 4.4725 20 10 20C15.527 20 20 15.5275 20 10C20 4.47309 15.5275 0 10 0ZM10 18.6046C5.25539 18.6046 1.39535 14.7446 1.39535 10C1.39535 5.25535 5.25539 1.39535 10 1.39535C14.7446 1.39535 18.6046 5.25535 18.6046 10C18.6046 14.7446 14.7446 18.6046 10 18.6046Z"
        fill="currentColor"
      />
      <path
        // eslint-disable-next-line max-len
        d="M10.0018 8.33594C9.40949 8.33594 8.98828 8.58609 8.98828 8.95465V13.9697C8.98828 14.2857 9.40949 14.6015 10.0018 14.6015C10.5679 14.6015 11.0285 14.2857 11.0285 13.9697V8.95457C11.0285 8.58605 10.5679 8.33594 10.0018 8.33594Z"
        fill="currentColor"
      />
      <path
        // eslint-disable-next-line max-len
        d="M10.0012 5.24219C9.3957 5.24219 8.92188 5.67656 8.92188 6.17676C8.92188 6.67699 9.39574 7.12453 10.0012 7.12453C10.5936 7.12453 11.0675 6.67699 11.0675 6.17676C11.0675 5.67656 10.5935 5.24219 10.0012 5.24219Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_5076_14424">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
