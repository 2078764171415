import React from 'react';
import { CheckboxProps as ACheckboxProps } from 'antd';

import * as Styled from './Checkbox.molecule.styled';

type CheckboxProps = ACheckboxProps;

export const Checkbox: React.FC<CheckboxProps> = (props) => {
  return <Styled.Container {...props} />;
};
