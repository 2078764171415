import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pipe } from 'fp-ts/function';
import * as RD from '@devexperts/remote-data-ts';

import { PORTFOLIO_TYPE, useActivePortfolioInfo } from '~/entities/protfolio';
import { usePortfolioOnboardingStepsHook, useGetWelcomePopupContent } from '@pages/portfolio';
import { modifyProfileSettingAction, userSettingsLoadingSelector } from '@store/settings';

import { useIsPortfolioOnboardingPassed } from './useIsPortfolioOnboardingPassed.hook';
import { useGetPortfolioSwitcherOnboarding } from './useGetPortfolioSwitcherOnboarding';
import {
  usePortfoliosSwitcherShowCount,
  PORTFOLIO_SWITCHER_MAX_SHOW_COUNT,
} from './usePortfoliosSwitcherShowCount.hook';

export const useGetPortfolioPageOnboarding = (enabled: boolean) => {
  const dispatch = useDispatch();

  /** Debug function to clean up onboarding portfolio page's onboarding states */
  useEffect(() => {
    // eslint-disable-next-line
    // @ts-ignore
    window.__clean = () =>
      dispatch(
        modifyProfileSettingAction({
          selfDirectedPortfolioOnboardingPassed: false,
          portfolioOnboardingPassed: false,
          preMadePortfolioOnboardingPassed: false,
          portfoliosSwitcherShowCount: 0,
        }),
      );
  }, [dispatch]);

  const onboardingPassed = useIsPortfolioOnboardingPassed();

  const isUserSettingsLoading = useSelector(userSettingsLoadingSelector);

  const welcomePopupInfo = useGetWelcomePopupContent();
  const [activePortfolioInfo] = useActivePortfolioInfo();
  const { steps: portfolioSteps, stepsHashMap: portfolioStepsHashMap } = usePortfolioOnboardingStepsHook(
    enabled && !onboardingPassed,
    350,
  );
  const portfolioSwitcherSteps = useGetPortfolioSwitcherOnboarding();
  const portfolioSwitcherShowCount = usePortfoliosSwitcherShowCount();

  const onOnboardingComplete = useCallback(() => {
    pipe(
      activePortfolioInfo,
      RD.map(({ portfolioType }) => {
        const portfoliosSwitcherShowCount =
          portfolioSwitcherShowCount >= PORTFOLIO_SWITCHER_MAX_SHOW_COUNT
            ? PORTFOLIO_SWITCHER_MAX_SHOW_COUNT
            : portfolioSwitcherShowCount + 1;

        switch (portfolioType) {
          case PORTFOLIO_TYPE.PRE_MADE:
            return dispatch(
              modifyProfileSettingAction({
                preMadePortfolioOnboardingPassed: true,
                portfoliosSwitcherShowCount,
              }),
            );
          case PORTFOLIO_TYPE.MIXED: {
            return dispatch(
              modifyProfileSettingAction({
                portfolioOnboardingPassed: true,
                portfoliosSwitcherShowCount,
              }),
            );
          }
          case PORTFOLIO_TYPE.SELF_DIRECT:
            return dispatch(
              modifyProfileSettingAction({
                selfDirectedPortfolioOnboardingPassed: true,
                portfoliosSwitcherShowCount,
              }),
            );
        }
      }),
    );
  }, [dispatch, activePortfolioInfo, portfolioSwitcherShowCount]);

  const steps = useMemo(() => {
    if (portfolioSwitcherShowCount >= PORTFOLIO_SWITCHER_MAX_SHOW_COUNT) {
      return portfolioSteps;
    }
    return [...portfolioSteps, ...portfolioSwitcherSteps];
  }, [portfolioSwitcherShowCount, portfolioSteps, portfolioSwitcherSteps]);

  return useMemo(
    () => ({
      onComplete: onOnboardingComplete,
      steps,
      portfolioStepsHashMap,
      welcomePopupInfo,
      isLoading: isUserSettingsLoading,
      isActive: !onboardingPassed,
    }),
    [onOnboardingComplete, steps, portfolioStepsHashMap, welcomePopupInfo, isUserSettingsLoading, onboardingPassed],
  );
};
