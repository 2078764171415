import * as React from 'react';

type Props = {
  className?: string;
};

export const IconCheckMark: React.FC<Props> = (props) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        /* eslint-disable-next-line max-len */
        d="M8 0C3.58867 0 0 3.58867 0 8C0 12.4113 3.58867 16 8 16C12.4113 16 16 12.4113 16 8C16 3.58867 12.4113 0 8 0ZM7.93933 10.2793C7.68133 10.5373 7.342 10.666 7.00133 10.666C6.66067 10.666 6.318 10.536 6.05733 10.276L4.20267 8.47867L5.13133 7.52067L6.99333 9.32533L10.866 5.52467L11.802 6.47467L7.93933 10.2793Z"
        fill="CurrentColor"
      />
    </svg>
  );
};
