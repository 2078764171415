import styled from 'styled-components';

import { String } from '../String.styled';

export const AmountInput = styled(String).attrs({
  $bordered: true,
  $br: 'm',
  $fs: 'xxl',
  $fw: 700,
  $ff: 'EkoTitle, Philosopher',
})``;
