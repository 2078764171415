import { constVoid } from 'fp-ts/function';

import { PageOnboardingProps } from '~/widgets/PageOnboarding';

export const PAGE_EMPTY_ONBOARDING_RESULTS: PageOnboardingProps = {
  isOpen: false,
  id: '',
  steps: [],
  onComplete: constVoid,
};
