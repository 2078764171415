import React, { PropsWithChildren } from 'react';

import * as Styled from './Card.organism.styled';

type Props = {
  header?: React.ReactNode;
  className?: string;
  disableContentPadding?: boolean;
  before?: React.ReactNode;
  after?: React.ReactNode;
  onClick?: VoidFunction;
  tabIndex?: number;
};

export const Card = React.forwardRef<HTMLDivElement, PropsWithChildren<Props>>((props, ref) => (
  <Styled.Container ref={ref} className={props.className} onClick={props.onClick} tabIndex={props.tabIndex}>
    {props.header}
    <Styled.Content $disableContentPadding={props.disableContentPadding}>
      {props.before && <Styled.BeforeContainer>{props.before}</Styled.BeforeContainer>}
      <Styled.ChildrenContent>{props.children}</Styled.ChildrenContent>
      {props.after}
    </Styled.Content>
  </Styled.Container>
));
