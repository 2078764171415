import React, { PropsWithChildren, useCallback } from 'react';

import { GlobalEvent, useReactOnGlobalEvent } from '@utils/globalEvent';
import { IconCrossRounded } from '~/ui/kit/atoms/IconCrossRounded';
import Loader from '~/ui/components/Loader/Loader';

import * as Styled from './Popup.organism.styled';

export type PopupProps = {
  isVisible: boolean;
  onClickYes?: VoidFunction;
  yesButtonOrder?: 0 | 1;
  onClickNo: (closeAll?: boolean) => void;
  title?: React.ReactNode;
  // todo revise titleInHeader logic
  titleInHeader?: boolean;
  subText?: React.ReactNode;
  yesBtnText?: React.ReactNode;
  hideConfirm?: boolean;
  noBtnText?: React.ReactNode;
  disableControls?: boolean;
  isLoading: boolean;
  description?: React.ReactNode;
  onClosed?: VoidFunction;
  modalClassName?: string;
  className?: string;
  disableClose?: boolean;
  autoFocus?: boolean;
  zIndex?: number | string;
};

export const Popup: React.FC<PropsWithChildren<PopupProps>> = ({ onClosed, onClickNo, ...props }) => {
  const handleClickNo = useCallback(
    (eventOrForceClose: boolean | any) => {
      if (eventOrForceClose === true) {
        onClickNo(true);
      } else {
        onClickNo();
      }
    },
    [onClickNo],
  );

  const reactOnGlobalPopupClose = useCallback(() => {
    handleClickNo(true);

    if (onClosed) {
      onClosed();
    }
  }, [onClosed, handleClickNo]);

  useReactOnGlobalEvent(GlobalEvent.HIDE_ALL_POPUPS, reactOnGlobalPopupClose);

  return (
    <>
      <Styled.GlobalStyles />
      <Styled.Container
        wrapClassName="eko-modal-wrapper"
        onClosed={onClosed}
        isOpen={props.isVisible}
        toggle={handleClickNo}
        autoFocus={props.autoFocus}
        centered
        returnFocusAfterClose
        className={props.className}
        modalClassName={props.modalClassName}
        zIndex={props.zIndex}
      >
        {!props.disableClose && (
          <Styled.CloseButton onClick={handleClickNo}>
            <IconCrossRounded />
          </Styled.CloseButton>
        )}
        {(!props.disableClose || (props.title && props.titleInHeader)) && (
          <Styled.HeaderContainer $titleInHeader={props.titleInHeader || false}>
            {props.title && props.titleInHeader && <Styled.BaseTitle>{props.title}</Styled.BaseTitle>}
          </Styled.HeaderContainer>
        )}
        <Styled.ContentContainer>
          {props.title && !props.titleInHeader && <Styled.Title>{props.title}</Styled.Title>}
          {props.subText && <Styled.Subtitle>{props.subText}</Styled.Subtitle>}
          {props.children && <Styled.ChildrenContent>{props.children}</Styled.ChildrenContent>}
          {!props.disableControls && (
            <Styled.ActionsContainer $disableBottomMargin={props.disableClose}>
              {!props.hideConfirm && (
                <Styled.Confirm $order={props.yesButtonOrder} onClick={props.onClickYes} disabled={props.isLoading}>
                  {props.isLoading ? <Loader /> : props.yesBtnText}
                </Styled.Confirm>
              )}
              {props.noBtnText && <Styled.Decline onClick={handleClickNo}>{props.noBtnText}</Styled.Decline>}
            </Styled.ActionsContainer>
          )}
          {props.description && <Styled.Description>{props.description}</Styled.Description>}
        </Styled.ContentContainer>
      </Styled.Container>
    </>
  );
};
