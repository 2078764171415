import { Menu } from 'antd';
import styled from 'styled-components';

import { getFontSize } from '~/ui/kit/constants/fonts';
import { gaps } from '~/ui/kit/constants/sizes';

export const Container = styled(Menu)`
  ${getFontSize('s')};
  line-height: 1.625em;

  &.ant-menu {
    &:before,
    &:after {
      content: none;
    }

    &-root&-vertical {
      border-inline-end: none;
      display: flex;
      flex-direction: column;
      gap: ${gaps.xxl}px;
      background: inherit;
    }

    &-root {
      &:focus-visible {
        outline-color: var(--eko-primary-color-light-5);
      }
    }

    .ant-menu-item-divider {
      border-color: var(--eko-fifth-color);
    }

    .ant-menu-item-group-title {
      padding: 0 ${gaps.m}px;
      font-size: inherit;
      line-height: inherit;
      font-weight: 600;
    }

    .ant-menu-item,
    .ant-menu-item-group-title {
      margin: 0;
    }

    .ant-menu-item-group,
    .ant-menu-item-group .ant-menu-item-group-list {
      display: flex;
      flex-direction: column;
      gap: ${gaps.s}px;
    }

    > .ant-menu-item,
    .ant-menu-item-group .ant-menu-item-group-list .ant-menu-item {
      &:last-child {
        margin: 0;
      }
    }

    .ant-menu-item.ant-menu-item,
    .ant-menu-item.ant-menu-item-only-child,
    .ant-menu-item-group .ant-menu-item-group-list .ant-menu-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: ${gaps.s}px ${gaps.m}px;
      height: auto;
      font-size: inherit;
      line-height: inherit;
      font-weight: 600;
      background-color: var(--eko-secondary-color);

      &.ant-menu-item-selected {
        color: var(--eko-primary-color);
        background-color: var(--eko-primary-color-light-2);
      }

      &:focus-visible {
        outline-color: var(--eko-primary-color-light-5);
      }

      &:active {
        background-color: var(--eko-primary-color-light-2);
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.06);
        color: inherit;
      }
    }
  }
`;
