import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { ButtonProps } from './Button.atom.types';
import * as Styled from './Button.styled';

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ onClick: onClickProp, fs, br, isLoading, link, noDecoration, ...props }, ref) => {
    const history = useHistory();
    const $br = useMemo(() => {
      if (!props.size) {
        return br;
      }

      switch (props.size) {
        case 'small':
          return 's';
        case 'middle':
          return 'm';
        case 'large':
          return 'l';
      }
    }, [br, props.size]);

    const loadingContent = useMemo(
      () => (
        <>
          <Styled.Spinner />
        </>
      ),
      [],
    );

    const onClick = useCallback(
      (e: any) => {
        void (onClickProp && onClickProp(e));

        if (link) {
          history.push(link);
        }
      },
      [history, onClickProp, link],
    );

    return (
      <Styled.Container
        {...props}
        $noDecoration={noDecoration}
        $href={link}
        onClick={onClick}
        $br={$br}
        $fs={fs}
        ref={ref}
      >
        {isLoading ? loadingContent : props.children}
      </Styled.Container>
    );
  },
);
