import styled from 'styled-components';
import { Skeleton as ASkeleton } from 'antd';

export const Block = styled(ASkeleton.Button)`
  &.ant-skeleton {
    &-element &-button {
      height: 100%;
      width: 100%;
      min-width: unset;
    }
  }
`;

export const LineSkeleton = styled(Block)`
  height: 1.5em;
  width: 100%;
`;

export const LinesItemSkeleton = styled(LineSkeleton)`
  display: block;
  height: 1em;
  margin-bottom: 0.5em;

  &:first-child {
    margin-top: 0.25em;
  }

  &:last-child {
    margin-bottom: 0.25em;
    width: 25%;
  }
`;

LinesItemSkeleton.defaultProps = {
  active: true,
  block: true,
};

LineSkeleton.defaultProps = {
  active: true,
  block: true,
};

Block.defaultProps = {
  active: true,
  block: true,
};
