import React from 'react';

export const IconArrowNext: React.FC<{ className?: string }> = (props) => (
  <svg className={props.className} viewBox="0 0 28 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      // eslint-disable-next-line max-len
      d="M27.7071 8.70711C28.0976 8.31658 28.0976 7.68342 27.7071 7.2929L21.3431 0.928934C20.9526 0.53841 20.3195 0.53841 19.9289 0.928934C19.5384 1.31946 19.5384 1.95262 19.9289 2.34315L25.5858 8L19.9289 13.6569C19.5384 14.0474 19.5384 14.6805 19.9289 15.0711C20.3195 15.4616 20.9526 15.4616 21.3431 15.0711L27.7071 8.70711ZM-8.74228e-08 9L27 9L27 7L8.74228e-08 7L-8.74228e-08 9Z"
      fill="currentColor"
    />
  </svg>
);
