import React, { FC } from 'react';

type Props = {
  className?: string;
};

export const IconLocation: FC<Props> = (props) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 18 20" fill="none">
      <path
        // eslint-disable-next-line max-len
        d="M8.965 20.0068L8.3841 19.5088C7.58328 18.8383 0.59082 12.7993 0.59082 8.38199C0.59082 3.75707 4.34008 0.0078125 8.965 0.0078125C13.5899 0.0078125 17.3392 3.75707 17.3392 8.38199C17.3392 12.7994 10.3467 18.8383 9.54922 19.5121L8.965 20.0068ZM8.965 1.81855C5.34184 1.82266 2.4057 4.75879 2.4016 8.38195C2.4016 11.157 6.70363 15.5902 8.965 17.6192C11.2264 15.5893 15.5284 11.1537 15.5284 8.38195C15.5243 4.75879 12.5882 1.8227 8.965 1.81855Z"
        fill="currentColor"
      />
      <path
        // eslint-disable-next-line max-len
        d="M8.96502 11.7014C7.13174 11.7014 5.64557 10.2152 5.64557 8.38195C5.64557 6.54867 7.13174 5.0625 8.96502 5.0625C10.7983 5.0625 12.2845 6.54867 12.2845 8.38195C12.2845 10.2152 10.7983 11.7014 8.96502 11.7014ZM8.96502 6.72219C8.04838 6.72219 7.3053 7.46527 7.3053 8.38191C7.3053 9.29855 8.04838 10.0416 8.96502 10.0416C9.88166 10.0416 10.6247 9.29855 10.6247 8.38191C10.6247 7.46527 9.8817 6.72219 8.96502 6.72219Z"
        fill="currentColor"
      />
    </svg>
  );
};
