import React, { FC, useCallback } from 'react';
import { MenuProps } from 'antd';
import { MenuClickEventHandler } from 'rc-menu/lib/interface';

import * as S from './Menu.styled';

export type MenuItem = Required<MenuProps>['items'][number];

type Props = {
  className?: string;
  items: MenuItem[];
  activeMenuItem?: string;
  onItemSelect: (menuItemKey: string) => void;
};

export const Menu: FC<Props> = ({ onItemSelect, ...props }) => {
  const onClick: MenuClickEventHandler = useCallback(
    ({ key }) => {
      onItemSelect(key);
    },
    [onItemSelect],
  );

  return (
    <S.Container
      className={props.className}
      items={props.items}
      defaultActiveFirst
      selectedKeys={props.activeMenuItem ? [props.activeMenuItem] : []}
      onClick={onClick}
    />
  );
};
