import React, { createRef, useCallback, useEffect, useMemo, useRef } from 'react';
import { SelectProps as ASelectProps } from 'antd';
import { RefSelectProps } from 'antd';

import { useOutsideEvent } from '~/ui/utils/effects/useOutsideEvent.hook';

import * as Styled from './Select.molecule.styled';

export type SelectProps = ASelectProps & {
  fullWidth?: boolean;
  before?: React.ReactNode;
  after?: React.ReactNode;
  isTextLabelDropdown?: boolean;
  valueControl?: React.ReactNode;
};

const defaultIcon = <Styled.Icon />;

export const Select = React.forwardRef<RefSelectProps, SelectProps>(
  (
    {
      suffixIcon,
      dropdownRender,
      fullWidth,
      before,
      after,
      isTextLabelDropdown,
      valueControl,
      className,
      virtual = false,
      ...props
    },
    ref,
  ) => {
    const dropdownRenderRef = createRef<HTMLDivElement>();

    const renderDropdown: typeof dropdownRender = useCallback(
      (children: React.ReactElement) => {
        return (
          <>
            <Styled.Dropdown ref={dropdownRenderRef} $fullWidth={fullWidth}>
              {before}
              {children}
              {after && (
                <>
                  <Styled.Divider />
                  {after}
                </>
              )}
            </Styled.Dropdown>
          </>
        );
      },
      [fullWidth, before, dropdownRenderRef, after],
    );

    const selectRef = useRef<RefSelectProps | null>();

    const setRef: React.RefCallback<RefSelectProps> = useCallback(
      (r) => {
        selectRef.current = r;

        if (typeof ref === 'function') {
          ref(r);
        } else {
          if (ref) {
            ref.current = r;
          }
        }
      },
      [ref],
    );

    const isVisibleRef = useRef<boolean>(props.defaultOpen || false);

    const onVisibilityChange = useCallback((val: boolean) => {
      setTimeout(() => {
        isVisibleRef.current = val;
      }, 200);
    }, []);

    const hideDropdown = useCallback(() => {
      if (isVisibleRef.current) {
        void (selectRef.current?.blur && selectRef.current.blur());
      }
    }, []);

    useOutsideEvent(dropdownRenderRef, 'touchStart', hideDropdown);

    useEffect(() => {
      document.addEventListener('outsideScroll', hideDropdown);

      return () => {
        document.removeEventListener('outsideScroll', hideDropdown);
      };
    }, [hideDropdown]);

    const selectContent = useMemo(
      () => (
        // todo revise typings and try to find a way to type generics
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <Styled.Select
          virtual={virtual}
          onDropdownVisibleChange={onVisibilityChange}
          {...props}
          $hideValue={!!valueControl}
          $isTextLabelDropdown={isTextLabelDropdown}
          ref={setRef}
          $fullWidth={fullWidth}
          suffixIcon={suffixIcon === undefined ? defaultIcon : suffixIcon}
          dropdownRender={dropdownRender || renderDropdown}
        />
      ),
      [
        dropdownRender,
        fullWidth,
        isTextLabelDropdown,
        onVisibilityChange,
        props,
        renderDropdown,
        setRef,
        suffixIcon,
        valueControl,
        virtual,
      ],
    );

    return (
      <Styled.Container className={className}>
        {valueControl}
        {props.loading ? <Styled.Skeleton /> : selectContent}
      </Styled.Container>
    );
  },
);
