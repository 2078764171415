import styled, { css } from 'styled-components';

import TopHeader from '@components/TopHeader/TopHeader';
import { gaps } from '~/ui/kit/constants/sizes';
import { media } from '~/ui/utils/media';

export const Container = styled('div')``;

export const PageHeader = styled(TopHeader)`
  margin-bottom: ${gaps.m}px;
`;

export const ContentContainer = styled.div<{ desktopWidth$?: number }>`
  width: 100%;

  ${({ desktopWidth$ }) =>
    desktopWidth$ &&
    css`
      ${media.md`
      max-width: ${desktopWidth$}px;
    `}
    `}
`;
