import styled, { createGlobalStyle, css } from 'styled-components';

import { media } from '~/ui/utils/media';
import { onboardingActiveElement } from '~/widgets/PageOnboarding';
import { PageOnboardingProvider } from '~/widgets/PageOnboarding';

export const GlobalStyles = createGlobalStyle<{ $headerHeight: number }>`
  body {
    // default values for the initial render while ref was not initiated yet
    --eko-header-height: 56px;
    ${media.md`
    --eko-header-height: 77px;    
  `};
    ${media.lg`
    --eko-header-height: 96px;
  `}

    ${({ $headerHeight }) =>
      $headerHeight &&
      css`
        --eko-header-height: ${$headerHeight}px !important;
      `}
  }
`;

export const Container = styled.div``;

export const Onboarding = styled(PageOnboardingProvider)`
  & .${onboardingActiveElement} {
    background: white;
  }
`;
