import { Input } from 'antd';
import styled from 'styled-components';

import { getBorderRadius } from '~/ui/kit/constants/sizes';
import { fontSize, font } from '~/ui/kit/constants/fonts';

import * as CommonStyles from './Input.styled';
import { InputStyledProps } from './Input.types';

export const TextArea = styled(Input.TextArea)<InputStyledProps>`
  border-radius: 0;
  border: none;

  &.ant-input {
    ${({ $bordered }) => ($bordered ? CommonStyles.borderedStyles : CommonStyles.underlinedBorder)};
    ${CommonStyles.errorColors};
    ${getBorderRadius};
    ${fontSize};
    ${font};
  }
`;

TextArea.defaultProps = {
  $bordered: true,
  $br: 'xs',
};
