import styled, { css } from 'styled-components';
import { Radio as ARadio, RadioGroupProps } from 'antd';
import { media } from '~/ui/utils/media';
import { gaps, getEkoBorderRadius } from '~/ui/kit/constants/sizes';

const verticalLayout = css`
  display: flex;
  flex-direction: column;
  text-align: left;

  .ant-radio-wrapper {
  }
`;

const solidGroupStyle = css<RadioGroupProps>`
  gap: 0;
  padding: ${gaps.xxs / 2}px;

  &.ant-radio-group-solid {
    background: var(--eko-primary-color-light-2);
    font-weight: bold;
    color: var(--eko-primary-color);

    border-radius: ${getEkoBorderRadius({ $br: 'l' })};

    & .ant-radio-button-wrapper {
      width: 100%;
      text-align: center;
      color: inherit;
      background: unset;
      border: none;
      height: auto;
      padding: ${gaps.s - gaps.xxs / 2}px ${gaps.xl}px;
      border-radius: inherit;

      &.ant-radio-button-wrapper-disabled:not(&.ant-radio-button-wrapper-checked) {
        opacity: 0.5;
      }

      &.ant-radio-button-wrapper-checked {
        background: white;

        &:hover {
          color: inherit;
        }
      }

      &:not(:first-child)::before {
        content: unset;
      }
    }
  }
`;

export const Container = styled(ARadio.Group)<{
  $layout?: 'vertical' | 'horizontal';
  $hideHtmlControl?: boolean;
  $inputOptionType?: 'button';
}>`
  display: flex;
  gap: ${gaps.m}px;

  .ant-radio-wrapper {
    font-size: 14px !important;
    margin-right: 0 !important;

    ${({ $hideHtmlControl }) =>
      $hideHtmlControl &&
      css`
        & .ant-radio-inner {
          display: none;
        }
      `}

    ${media.sm`
      font-size: 16px !important;
    `}

    .ant-radio {
      & + span {
        width: 100%;
      }

      &-inner {
        border-color: grey;
        border-width: 1.5px;
        transition: none;
      }
    }

    &-checked {
      color: var(--eko-primary-color);

      .ant-radio {
        color: currentColor;
      }

      .ant-radio-inner {
        background-color: unset;
        border-color: unset;

        &:after {
          transform: scale(0.575);
          background-color: currentColor;
          border-color: currentColor;
        }
      }
    }
  }

  ${({ $layout }) => $layout === 'vertical' && verticalLayout};
  ${({ $inputOptionType }) => $inputOptionType === 'button' && solidGroupStyle}};
`;
