import { useSelector } from 'react-redux';
import { constTrue, identity, pipe } from 'fp-ts/function';
import { useMemo } from 'react';
import * as RD from '@devexperts/remote-data-ts';

import { profileSettingsSelector, useSettingsEq } from '@store/settings';
import { sequenceT } from '@utils/RemoteData';
import { PORTFOLIO_TYPE, useActivePortfolioInfo } from '~/entities/protfolio';

export const useIsPortfolioOnboardingPassed = () => {
  const [activePortfolioInfo] = useActivePortfolioInfo();
  const userSettings = useSelector(profileSettingsSelector, useSettingsEq.equals);

  return useMemo(
    () =>
      pipe(
        sequenceT(userSettings, activePortfolioInfo),
        RD.map(
          ([
            {
              portfolioOnboardingPassed: mixedPortfolioOnboardingPassed,
              selfDirectedPortfolioOnboardingPassed,
              preMadePortfolioOnboardingPassed,
            },
            { portfolioType },
          ]) => {
            switch (portfolioType) {
              case PORTFOLIO_TYPE.PRE_MADE:
                return !!preMadePortfolioOnboardingPassed;
              case PORTFOLIO_TYPE.MIXED:
                return !!mixedPortfolioOnboardingPassed;
              case PORTFOLIO_TYPE.SELF_DIRECT:
                return !!selfDirectedPortfolioOnboardingPassed;
            }
            return !!mixedPortfolioOnboardingPassed;
          },
        ),
        RD.fold(constTrue, constTrue, constTrue, identity),
      ),
    [userSettings, activePortfolioInfo],
  );
};
