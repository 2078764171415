import styled from 'styled-components';

import { gaps } from '~/ui/kit/constants/sizes';
import { IconWebCursor } from '~/ui/kit/atoms/IconWebCursor';

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: ${gaps.xxs}px;
  color: var(--eko-primary-color);
`;

export const SiteIcon = styled(IconWebCursor)`
  width: 1.25em;
  height: 1.25em;
`;

export const SiteLink = styled.a.attrs({
  target: '_blank',
})`
  text-decoration: none;
  color: inherit !important;
`;
