import { InputRef } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';

import * as money from '@utils/money';

import * as Styled from './Amount.molecule.styled';

const floatRegExp = new RegExp('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$');

type AmountProps = {
  value: number;
  onChange: (amount: number) => void;
  prefix?: string;
  precision?: number;
  defaultValue?: number;
  className?: string;
  suffix?: React.ReactNode;
};
export const Amount = React.forwardRef<InputRef, AmountProps>(
  ({ onChange: onChangeProp, precision = 2, className, suffix, ...props }, ref) => {
    const formatPattern = useMemo(() => {
      const decimalsPart = new Array(precision).fill('0').join('');
      if (precision > 2) {
        const [_, __, ...rest] = decimalsPart.split('');
        return `0,0[.]00[${rest.join('')}]}`;
      }
      return `0,0[.]${decimalsPart}`;
    }, [precision]);

    const prefix = props.prefix || '';
    const [localValue, setLocalValue] = useState(
      props.defaultValue ? money.format(props.defaultValue, formatPattern) : '0',
    );

    useEffect(() => {
      if (props.value && !Number.isNaN(Number(props.value))) {
        setLocalValue(props.value.toString());
      }
    }, [props.value]);

    useEffect(
      () => {
        const numberAmount = money.parse(localValue.replace(prefix, ''));
        if (localValue === prefix || localValue === '') {
          onChangeProp(0);
        } else {
          if (!Number.isNaN(numberAmount)) {
            onChangeProp(numberAmount);
          }
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [localValue, prefix],
    );

    return (
      <Styled.AmountInput
        className={className}
        suffix={suffix}
        ref={ref}
        type="text"
        inputMode="decimal"
        value={`${prefix}${localValue}`}
        onBlur={() => {
          setLocalValue(money.format(localValue, formatPattern));
        }}
        onFocus={() => {
          setLocalValue(money.parse(localValue).toString());
        }}
        onChange={(e) => {
          const amount = e.target.value.replace(prefix, '').replace(',', '.');
          if (amount === '') {
            setLocalValue('0');
          }
          if (amount === '.') {
            setLocalValue('0.');
          }
          if (floatRegExp.test(amount)) {
            const [_, decimalPart] = amount.split('.');
            if (decimalPart && decimalPart.length > precision) {
              return;
            }
            setLocalValue(amount);
          }
        }}
      />
    );
  },
);
