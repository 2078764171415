import React, { FC } from 'react';
import loadable from '@loadable/component';

import { FAQSkeleton } from './ui/Skeleton';

import * as S from './FAQ.common.styled';

type Props = {
  className?: string;
};

const FAQPageContent = loadable(() => import('./FAQ.page'), { fallback: FAQSkeleton });

export const FAQPage: FC<Props> = (props) => {
  return (
    <S.Container title="Knowledge center" className={props.className}>
      <FAQPageContent />
    </S.Container>
  );
};
