import React, { PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';
import * as RD from '@devexperts/remote-data-ts';
import { pipe } from 'fp-ts/function';

import organizationAPI from '@api/endpoints/organization';
import { ApiError, mapApiError } from '@models/ApiError';
import { response } from '@api/helpers';

import * as S from './ErrorPageTemplate.styled';

type Props = {
  className?: string;
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  header?: React.ReactNode;
  icon?: React.ReactNode;
  actionLabel?: React.ReactNode;
  onAction?: VoidFunction;
  link?: string;
  disableAction?: boolean;
  loadStyles?: boolean;
};

const useOrgStyles = () => {
  const [state, setState] = useState<RD.RemoteData<ApiError, string>>(RD.initial);

  const load = useCallback(() => {
    setState(RD.pending);

    response(organizationAPI.retrieve())
      .then((s) => s.css || '')
      .then(RD.success)
      .catch((e) => RD.failure(mapApiError(e)))
      .then(setState);
  }, []);

  return [state, load] as const;
};

export const ErrorPageTemplate: React.FC<PropsWithChildren<Props>> = ({
  title,
  subtitle = 'Routine maintenance in progress, please try again later',
  onAction,
  actionLabel = 'Try again',
  icon = <S.Icon />,
  link,
  disableAction = false,
  loadStyles = false,
  ...props
}) => {
  const [orgStyles, loadOrgStyes] = useOrgStyles();

  useEffect(() => {
    if (loadStyles) {
      loadOrgStyes();
    }
  }, [loadStyles, loadOrgStyes]);

  const styles = useMemo(() => pipe(orgStyles, RD.toUndefined), [orgStyles]);

  const onClick = useCallback(() => {
    if (onAction) {
      onAction();
      return;
    }

    window.location.reload();
  }, [onAction]);

  if (RD.isPending(orgStyles)) {
    return null;
  }

  return (
    <S.Container>
      {styles && <style>{styles}</style>}
      {icon && <S.IconContainer>{icon}</S.IconContainer>}
      {props.header}
      {title && <S.Title>{title}</S.Title>}
      <S.SubTitle>{subtitle}</S.SubTitle>
      {props.children}
      {!disableAction && (
        <S.ActionButton link={link} onClick={onClick}>
          {actionLabel}
        </S.ActionButton>
      )}
    </S.Container>
  );
};
