import styled from 'styled-components';
import { Skeleton } from '~/ui/kit/atoms/Skeleton';
import { gaps } from '~/ui/kit/constants/sizes';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${gaps.s}px;
`;

export const MenuSkeletonItem = styled(Skeleton.Block).attrs({ block: true })`
  height: 50px;
`;
