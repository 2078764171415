import React, { useMemo } from 'react';
import { RadioGroupProps } from 'antd';

import * as S from './Radio.styled';

type Props = {
  className?: string;
  layout?: 'vertical' | 'horizontal';
  hideHtmlControl?: boolean;
  inputOptionType?: 'button';
  customValue?: string;
  hoverDescription?: React.ReactNode;
} & RadioGroupProps;

export const RadioGroup: React.FC<Props> = ({ inputOptionType, layout, hideHtmlControl, customValue, ...props }) => {
  const options = useMemo(
    () => (inputOptionType === 'button' ? ({ buttonStyle: 'solid', optionType: 'button' } as const) : {}),
    [inputOptionType],
  );

  const valueProps = useMemo(() => (customValue ? { value: customValue } : {}), [customValue]);

  return (
    <S.Container
      $layout={layout}
      $hideHtmlControl={hideHtmlControl}
      $inputOptionType={inputOptionType}
      {...props}
      {...valueProps}
      {...options}
      className={props.className}
    ></S.Container>
  );
};
