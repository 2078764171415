import styled from 'styled-components';

import { media } from '~/ui/utils/media';
import { accentFont, fontSize } from '~/ui/kit/constants/fonts';
import { gaps } from '~/ui/kit/constants/sizes';
import { Button } from '~/ui/kit/atoms/Button';
import { ErrorIcon } from './ErrorIcon';

export const Icon = styled(ErrorIcon)`
  color: var(--eko-primary-color);
`;

export const IconContainer = styled.div`
  margin-bottom: ${gaps.m}px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: relative;
`;

export const Title = styled.div.attrs({ $fs: 'xxl' })`
  ${fontSize};
  ${accentFont};
  font-weight: bold;
`;

export const SubTitle = styled.div.attrs({ $fs: 'm' })`
  ${fontSize};
  color: var(--eko-third-color);
  text-align: center;
  max-width: 90%;

  ${media.md`
    max-width: 50%;
  `}
`;

export const ActionButton = styled(Button).attrs({
  br: 'l',
  type: 'primary',
  size: 'large',
})`
  min-width: 240px;
  margin-top: ${gaps.xxl}px;
  font-weight: bold;
`;
