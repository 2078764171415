import { useMemo } from 'react';
import { pipe } from 'fp-ts/function';
import * as RD from '@devexperts/remote-data-ts';
import { useSelector } from 'react-redux';
import { profileSettingsSelector, useSettingsEq } from '@store/settings';

export const PORTFOLIO_SWITCHER_MAX_SHOW_COUNT = 2;

export const usePortfoliosSwitcherShowCount = () => {
  const userSettings = useSelector(profileSettingsSelector, useSettingsEq.equals);

  return useMemo(
    () =>
      pipe(
        userSettings,
        RD.map((settings) =>
          settings.portfoliosSwitcherShowCount === undefined
            ? PORTFOLIO_SWITCHER_MAX_SHOW_COUNT
            : settings.portfoliosSwitcherShowCount,
        ),
        RD.getOrElse(() => PORTFOLIO_SWITCHER_MAX_SHOW_COUNT),
      ),
    [userSettings],
  );
};
