import styled from 'styled-components';
import { baseFont, getFontSize } from '~/ui/kit/constants/fonts';

export const Container = styled.span<{ $level?: number }>`
  font-weight: bold;
  display: contents;
  color: var(--eko-title-color);
  ${baseFont};
  ${({ $level }) => {
    switch ($level) {
      case 1:
        return getFontSize('xxl');
      case 2:
        return getFontSize('xl');
      case 3:
        return getFontSize('l');
      case 4:
        return getFontSize('m');
      case 5:
        return getFontSize('s');
      case 6:
        return getFontSize('xs');
    }
  }};

  > * {
    display: inline-block;
    font-weight: inherit;
    // TODO refactor and enable
    // font-size: inherit;
    margin: 0;
  }
`;
