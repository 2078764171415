import { createGlobalStyle } from 'styled-components';

import { fontSize, font } from '~/ui/kit/constants/fonts';
import { gaps, getBorderRadius } from '~/ui/kit/constants/sizes';
import { media } from '~/ui/utils/media';

import { InputStyledProps } from '../Input.types';
import * as CommonStyles from '../Input.styled';

export const Styles = createGlobalStyle<InputStyledProps>`
  .react-tel-input {
    .selected-flag {
      padding-left: ${gaps.m}px;

      ${media.md`
        padding-left: ${gaps.xl}px;
      `}
      
      &:hover {
        background: none;
      }
    }

    .form-control {
      width: 100%;
      height: auto;

      ${({ $bordered }) => ($bordered ? CommonStyles.borderedStyles : CommonStyles.underlinedBorder)};
      padding-left: ${gaps.m + 30}px;

      ${media.md`
        padding-left: ${gaps.xl + 30}px;
      `}
      ${CommonStyles.errorColors};
      ${getBorderRadius};
      ${fontSize};
      ${font};
    }
  }
`;
