import React, { useMemo } from 'react';
import { TitleProps as BaseTitleProps } from 'antd/lib/typography/Title';

import * as Styled from './Title.styled';

type TitleProps = BaseTitleProps;

export const Title: React.FC<TitleProps> = ({ level, ...props }) => {
  const Component = useMemo(() => {
    switch (level) {
      case 1:
        return 'h1' as const;
      case 2:
        return 'h2' as const;
      case 3:
        return 'h3' as const;
      case 4:
        return 'h4' as const;
      case 5:
        return 'h5' as const;
      default:
        return 'span' as const;
    }
  }, [level]);
  return <Styled.Container $level={level}>{React.createElement(Component, props, props.children)}</Styled.Container>;
};
