import styled from 'styled-components';

import { baseFont } from '~/ui/kit/constants/fonts';
import { Container as NavMenuContainer } from '~/ui/layouts/NavMenu/NavMenu.styled';
import { PageLayout } from '~/ui/layouts/Page';

export const Container = styled(PageLayout)`
  ${baseFont};

  ${NavMenuContainer} {
    @media (max-width: 991px) {
      background: none !important;
    }
  }
`;
